@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';

@import '~@blueprintjs/core/lib/scss/variables.scss';

@import '~tailwindcss/tailwind';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Inter, Rubik, Segoe UI, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
}

.#{$bp-ns}-navbar {
  padding: 0;
}

@font-face {
  font-family: 'Whitney';
  src: url('/fonts/Whitney-Book.woff2') format('woff2');
  font-display: swap;
}

code {
  font-family: monospace;
  // @apply px-1 py-1.5 bg-darkgray-2 rounded-[3px];
}

.prose {
  --tw-prose-bullets: theme('colors.lightgray.1');
}
